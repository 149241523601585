<template>
  <div class="reports-page-datatable">
    <el-table :data="data" style="width: 100%; font-size: 11px; padding: 0;" :max-height="screenHeight" class="el-table--mini" show-summary>
      <el-table-column sortable prop="branch_name" label="Filial" width="150" />
      <el-table-column sortable prop="name" label="Nome" width="300" />

      <el-table-column label="Tempo total (horas)">
        <el-table-column sortable prop="hours" label="Total" />
        <el-table-column sortable prop="hours_driving" label="Dirigindo" />
        <el-table-column sortable prop="hours_delivering" label="Entrega" />
      </el-table-column>

      <el-table-column label="Tempo médio (min)">
        <el-table-column sortable prop="min_customer_average" label="Cliente" />
        <el-table-column
          sortable
          prop="min_driving_average"
          label="Dirigindo"
        />
        <el-table-column
          sortable
          prop="min_delivering_average"
          label="Entrega"
        />
      </el-table-column>

      <el-table-column label="Total de notas">
        <el-table-column sortable prop="delivered" label="Entregue" />
        <el-table-column sortable prop="canceled" label="Cancelado" />
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import exportJson from '@/helpers/exportXLSX'

export default {
  name: 'report-table-stats-by-driver',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {
        return {
          filial: 'branch_name',
          filial_id: 'branch_id',
          nome: 'name',
          tempo_medio_por_cliente: 'min_customer_average',
          tempo_medio_entregando: 'min_delivering_average',
          tempo_medio_dirigindo: 'min_driving_average',
          horas_em_entrega: 'hours',
          horas_entregando: 'hours_delivering',
          horas_dirigindo: 'hours_driving',
          notas_canceladas: 'canceled',
          notas_entregues: 'delivered',
          notas_entregando: 'delivering',
          notas_checkin: 'delivering_checkin',
          notas_parciais: 'with_partial',
          clientes_com_erro: 'failed_customers',
          clientes_com_corrigidos: 'failed_customers_partial',
        }
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    screenHeight() {
      return (window.innerHeight -260)
    }
  },
  watch: {},
  mounted() {
    this.$emit('loaded')
  },
  methods: {
    DownloadXLS() {
      exportJson.DownloadXLS(this.data, this.fields)
    },
    DownloadCSV() {
      exportJson.DownloadCSV(this.data, this.fields)
    },
  },
}
</script>
